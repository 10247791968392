<!-- Created by henian.xu on 2018/7/20. -->

<template>
    <page>
        <!-- 表单区 -->
        <template slot="secondHeader">
            <el-form
                ref="queryForm"
                class="second-header npa-b"
                size="small"
                :inline="true"
                :model="queryFormModel"
            >
                <el-form-item
                    prop="name"
                    :label="$t('pageKey462', '分类名称')"
                >
                    <el-input
                        v-model="queryFormModel.name"
                        :placeholder="$t('pageKey463', '请输入分类名称')"
                    />
                </el-form-item>
                <el-form-item
                    prop="isAvailable"
                    :label="$t('pageKey67', '状态')"
                >
                    <el-select
                        v-model="queryFormModel.isAvailable"
                        :placeholder="$t('pageKey101', '请选择')"
                    >
                        <el-option
                            :label="$t('pageKey6', '全部')"
                            value=""
                        />
                        <el-option
                            :label="$t('pageKey7', '启用')"
                            value="1"
                        />
                        <el-option
                            :label="$t('pageKey8', '禁用')"
                            value="0"
                        />
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button
                        type="primary"
                        @click="onQuery"
                    >
                        {{ $t('pageKey9', '查询') }}
                    </el-button>
                    <el-button
                        type="success"
                        @click="onReset"
                    >
                        {{ $t('pageKey10', '重置') }}
                    </el-button>
                </el-form-item>
            </el-form>
        </template>

        <!-- 内容 -->
        <div
            class="body"
            slot="body"
            v-loading="querying"
        >
            <!-- 操作 -->
            <div
                class="ma-b"
            >
                <el-button-group>
                    <el-button
                        type="success"
                        icon="el-icon-plus"
                        size="small"
                        @click="onAdd"
                    >
                        {{ $t('pageKey15', '新增') }}
                    </el-button>
                </el-button-group>
            </div>

            <!-- 表格 -->
            <tree-table
                :lazy-load="getListData"
                init-not-load
                :expand-props="{
                    prop:'name',
                    label:$t('pageKey462', '分类名称'),
                    minWidth:200,
                }"
                ref="treeTable"
                stripe
                border
                size="small"
                style="width: 100%"
                :empty-text="$t('pageKey977', '暂无数据')"
            >
                <el-table-column
                    prop="id"
                    :label="$t('pageKey447', '类目ID')"
                    min-width="100"
                />
                <el-table-column
                    prop="goodsCatImage"
                    :label="$t('pageKey464', '分类图片')"
                    width="120"
                >
                    <template slot-scope="scope">
                        <ImageList
                            :data="scope.row.goodsCatImageUrl"
                        />
                    </template>
                </el-table-column>
                <el-table-column
                    :label="$t('pageKey12', '是否启用')"
                    width="90"
                >
                    <template slot-scope="scope">
                        <el-switch
                            v-model="scope.row.isAvailable"
                            @input="onIsAvailableInput($event,scope)"
                        />
                    </template>
                </el-table-column>
                <el-table-column
                    prop="orderNum"
                    :label="$t('pageKey11', '排序')"
                    min-width="100"
                />
                <el-table-column
                    fixed="right"
                    :label="$t('pageKey13', '操作')"
                    width="135"
                >
                    <template slot-scope="scope">
                        <el-button
                            type="text"
                            size="small"
                            @click="onTableAdd(scope.row)"
                            v-if="scope.row.lv === 1"
                        >
                            {{ $t('pageKey15', '新增') }}
                        </el-button>
                        <el-button
                            type="text"
                            size="small"
                            @click="onTableEdit(scope.row)"
                        >
                            {{ $t('pageKey16', '编辑') }}
                        </el-button>
                        <el-button
                            type="text"
                            size="small"
                            @click="onTableDelete(scope.row.id,scope.row)"
                        >
                            {{ $t('pageKey17', '删除') }}
                        </el-button>
                        <!--                        <el-button
                            type="text"
                            size="small"
                            @click="onGoodsForbidSell(scope.row)"
                        >
                            商品管理
                        </el-button>-->
                    </template>
                </el-table-column>
            </tree-table>
        </div>

        <!-- 弹框 -->
        <!-- 变量新增/编辑 -->
        <el-dialog
            :title="addDialog.formModel.id ? $t('pageKey16', '编辑') : $t('pageKey15', '新增')"
            center
            width="800px"
            :visible.sync="addDialog.isVisible"
            @closed="onAddDialogCancel"
        >
            <el-form
                ref="addForm"
                size="small"
                :model="addDialog.formModel"
                :rules="addDialog.formRules"
            >
                <el-form-item
                    v-if="addDialog.formModel.parentId"
                    prop="parentName"
                    :label="$t('pageKey452', '上级分类')"
                    label-width="11em"
                >
                    <el-input
                        v-model="addDialog.formModel.parentName"
                        disabled
                    />
                </el-form-item>
                <el-form-item
                    prop="name"
                    :label="$t('pageKey462', '分类名称')"
                    label-width="11em"
                >
                    <el-input
                        v-model="addDialog.formModel.name"
                    />
                </el-form-item>
                <el-form-item
                    prop="goodsCatImage"
                    :label="$t('pageKey464', '分类图片')"
                    label-width="11em"
                >
                    <upload
                        validate-md5
                        action="/rs/attachment/uploadFileLibrary"
                        :limit="1"
                        v-model="addDialog.formModel.goodsCatImage"
                    />
                </el-form-item>
                <el-form-item
                    prop="orderNum"
                    :label="$t('pageKey11', '排序')"
                    label-width="11em"
                >
                    <el-input-number
                        controls-position="right"
                        :min="1"
                        v-model.number="addDialog.formModel.orderNum"
                    />
                </el-form-item>
            </el-form>
            <div
                slot="footer"
                class="dialog-footer"
            >
                <el-button
                    type="primary"
                    size="small"
                    @click="onAddDialogConfirm"
                >
                    {{ $t('pageKey19', '确 定') }}
                </el-button>
                <el-button
                    size="small"
                    @click="onAddDialogCancel"
                >
                    {{ $t('pageKey20', '取 消') }}
                </el-button>
            </div>
        </el-dialog>

        <!--商品管理-->
        <el-dialog
            :title="$t('pageKey465', '商品管理')"
            center
            width="1200px"
            :visible.sync="goodsForbidSellDialog.isVisible"
            @closed="onGoodsForbidSellCancel"
        >
            <!-- 内容 -->
            <div class="body">
                <el-form
                    ref="goodsForbidSellDialogQueryForm"
                    class="second-header npa-b"
                    size="small"
                    :inline="true"
                    :model="goodsForbidSellDialog.queryFormModel"
                >
                    <el-form-item
                        prop="goodsName"
                        :label="$t('pageKey204', '商品名称')"
                    >
                        <el-input
                            v-model="goodsForbidSellDialog.queryFormModel.goodsName"
                            :placeholder="$t('pageKey205', '请输入商品名称')"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="goodsSn"
                        :label="$t('pageKey202', '商品货号')"
                    >
                        <el-input
                            v-model="goodsForbidSellDialog.queryFormModel.goodsSn"
                            :placeholder="$t('pageKey203', '请输入商品货号')"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="shopTagIdList"
                        :label="$t('pageKey327', '商品分组')"
                    >
                        <RemoteSelect
                            multiple
                            remote="/sp/shopTag/select"
                            :placeholder="$t('pageKey238', '请输入')"
                            :props="{id:'id',name:'tagName'}"
                            v-model="goodsForbidSellDialog.queryFormModel.shopTagIdList"
                        />
                    </el-form-item>
                    <el-form-item>
                        <el-button
                            type="primary"
                            @click="onGoodsForbidSellDialogQuery()"
                        >
                            {{ $t('pageKey9', '查询') }}
                        </el-button>
                        <el-button
                            type="success"
                            @click="onResetGoodsForbidSellDialog"
                        >
                            {{ $t('pageKey10', '重置') }}
                        </el-button>
                    </el-form-item>
                </el-form>

                <div
                    class="ma-b"
                >
                    <el-button-group>
                        <el-button
                            type="success"
                            icon="el-icon-plus"
                            size="small"
                            @click="onAddSelect()"
                        >
                            {{ $t('pageKey328', '选择商品') }}
                        </el-button>
                        <el-button
                            type="danger"
                            icon="el-icon-delete"
                            size="small"
                            @click="onDeleteSelectBatch"
                            :disabled="!goodsForbidSellDialog.currentTableSelect.length"
                        >
                            {{ $t('pageKey17', '删除') }}
                        </el-button>
                    </el-button-group>
                </div>

                <el-table
                    stripe
                    border
                    size="small"
                    :data="goodsForbidSellDialog.tableData"
                    style="width: 100%"
                    @selection-change="onGoodsForbidSellDialogSelectionChange"
                >
                    <el-table-column
                        type="selection"
                        width="36"
                    />
                    <el-table-column
                        prop="goodsName"
                        :label="$t('pageKey204', '商品名称')"
                        min-width="100"
                    />
                    <el-table-column
                        prop="goodsSn"
                        :label="$t('pageKey202', '商品编号')"
                        min-width="100"
                    />
                    <el-table-column
                        fixed="right"
                        :label="$t('pageKey13', '操作')"
                        width="45"
                    >
                        <template slot-scope="scope">
                            <el-button
                                type="text"
                                size="small"
                                @click="onDeleteSelect(scope.row.id)"
                            >
                                {{ $t('pageKey17', '删除') }}
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>

                <!-- 分页 -->
                <div class="ma-t ta-r">
                    <pagination
                        v-model="goodsForbidSellDialog.pagination"
                        @input="onGoodsForbidSellDialogQuery"
                    />
                </div>
            </div>
            <div
                slot="footer"
                class="dialog-footer"
            >
                <el-button
                    size="small"
                    @click="onGoodsForbidSellCancel"
                >
                    {{ $t('pageKey20', '取 消') }}
                </el-button>
            </div>
        </el-dialog>

        <el-dialog
            :title="$t('pageKey328', '选择商品')"
            center
            width="1200px"
            :visible.sync="selectDialog.isVisible"
            @closed="onSelectCancel"
        >
            <!-- 表单区 -->
            <el-form
                ref="selectDialogQueryForm"
                class="second-header npa-b"
                size="small"
                :inline="true"
                :model="selectDialog.queryFormModel"
            >
                <el-form-item
                    prop="goodsName"
                    :label="$t('pageKey204', '商品名称')"
                >
                    <el-input
                        v-model="selectDialog.queryFormModel.goodsName"
                        :placeholder="$t('pageKey205', '请输入商品名称')"
                    />
                </el-form-item>
                <el-form-item
                    prop="goodsSn"
                    :label="$t('pageKey202', '商品编号')"
                >
                    <el-input
                        v-model="selectDialog.queryFormModel.goodsSn"
                        :placeholder="$t('pageKey203', '请输入商品编号')"
                    />
                </el-form-item>
                <el-form-item
                    prop="shopTagIdList"
                    :label="$t('pageKey327', '商品分组')"
                >
                    <RemoteSelect
                        multiple
                        remote="/sp/shopTag/select"
                        :placeholder="$t('pageKey238', '请输入')"
                        :props="{id:'id',name:'tagName'}"
                        v-model="selectDialog.queryFormModel.shopTagIdList"
                    />
                </el-form-item>
                <el-form-item>
                    <el-button
                        type="primary"
                        @click="onSelectDialogQuery()"
                    >
                        {{ $t('pageKey9', '查询') }}
                    </el-button>
                    <el-button
                        type="success"
                        @click="onResetSelectDialog"
                    >
                        {{ $t('pageKey10', '重置') }}
                    </el-button>
                </el-form-item>
            </el-form>

            <!-- 内容 -->
            <div class="body">
                <!-- 表格 -->
                <el-table
                    stripe
                    border
                    size="small"
                    :data="selectDialog.tableData"
                    style="width: 100%"
                    height="400"
                    row-key="goodsId"
                    ref="selectDialogTable"
                    @selection-change="onSelectDialogSelectionChange"
                >
                    <el-table-column
                        type="selection"
                        width="36"
                    />
                    <el-table-column
                        prop="mainImageUrl"
                        :label="$t('pageKey215', '商品图片')"
                        width="135"
                    >
                        <template slot-scope="scope">
                            <image-list :data="scope.row.mainImageUrl"/>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="goodsName"
                        :label="$t('pageKey204', '商品名称')"
                        min-width="100"
                    />
                    <el-table-column
                        prop="goodsSn"
                        :label="$t('pageKey202', '商品编号')"
                        min-width="100"
                    />
                </el-table>

                <!-- 分页 -->
                <div class="ma-t ta-r">
                    <pagination
                        v-model="selectDialog.pagination"
                        @input="onSelectDialogQuery"
                    />
                </div>
            </div>
            <div
                slot="footer"
                class="dialog-footer"
            >
                <el-button
                    type="primary"
                    size="small"
                    @click="onSelectConfirm"
                >
                    {{ $t('pageKey19', '确 定') }}
                </el-button>
                <el-button
                    size="small"
                    @click="onSelectCancel"
                >
                    {{ $t('pageKey20', '取 消') }}
                </el-button>
            </div>
        </el-dialog>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';
import { queryFormTree } from 'vmf/src/mixins';
import { Utils } from 'vmf';

export default {
    name: 'ShopGoodsCat',
    mixins: [pagesMixin, queryFormTree],
    data() {
        return {
            queryFormModel: {
                name: '', // 分类名称
                isAvailable: '', // 状态
            },
            // 新增/编辑
            addDialog: {
                // 对话框显示
                isVisible: false,
                // 表单数据
                formModel: {
                    id: '',
                    parentId: '',
                    parentName: '',
                    name: '',
                    goodsCatImage: [],
                    orderNum: 1,
                    isAvailable: '',
                },
                // 表单校验规则
                formRules: {
                    name: [
                        {
                            required: true,
                            message: this.$t('pageKey463', '请输入分类名称'),
                            trigger: 'blur',
                        },
                        {
                            validator: this.validatorName,
                            message: this.$t('pageKey408', '分类名称不能重复'),
                            trigger: 'blur',
                        },
                    ],
                },
            },
            // 表格数据
            // tableData: [],
            // 表格当前行
            currentRow: {},
            // 表格当前选中行
            currentTableSelect: [],
            selectDialog: {
                // 对话框显示
                isVisible: false,
                // 查询数据
                queryFormModel: {
                    goodsName: '', // 商品名称
                    goodsSn: '', // 商品编号
                    shopTagIdList: [],
                },
                // 表单数据
                tableData: [],
                // 分页
                pagination: {},
                // 当前选择行
                currentTableSelect: [],
            },
            goodsForbidSellDialog: {
                // 对话框显示
                isVisible: false,
                // 查询数据
                queryFormModel: {
                    shopGoodsCatId: '',
                    goodsName: '',
                    goodsSn: '',
                    shopTagIdList: [],
                },
                // 表单数据
                tableData: [],
                // 分页
                pagination: {},
                currentTableSelect: [],
            },
        };
    },
    methods: {
        /* -- 方法 -- */
        // 请求列表数据
        getListData({ row = {} } = {}) {
            this.querying = true;
            return this.$api.Sp.ShopGoodsCat.data({
                pId: row ? row.id : null,
                ...this.queryFormModel,
            }).then(json => {
                this.querying = false;
                const res = json.data.data.sort((prev, next) => {
                    return prev.orderNum - next.orderNum;
                });
                return Promise.resolve(res);
            });
        },
        /* -- 事件 -- */
        onAdd() {
            this.addDialog.isVisible = true;
            this.$nextTick(() => {
                const { formModel } = this.addDialog;
                const { addForm } = this.$refs;
                addForm.resetFields();
                // id/parentId 不在表单之内所以要手机重置
                formModel.id = '';
                formModel.parentId = '';
            });
        },
        onTableSelectionChange(val) {
            this.currentTableSelect = val;
        },
        onTableAdd(row) {
            this.addDialog.isVisible = true;
            this.$nextTick(() => {
                const { formModel } = this.addDialog;
                const { addForm } = this.$refs;
                addForm.resetFields();
                formModel.id = '';
                formModel.parentId = row.id;
                formModel.parentName = row.name;
            });
            this.currentRow = row;
        },
        onTableEdit(row) {
            const { formModel } = this.addDialog;
            this.$api.Sp.ShopGoodsCat.getDetail({ id: row.id }).then(json => {
                const res = json.data.data;
                this.$utils.formModelMerge(formModel, res);
            });
            this.addDialog.isVisible = true;
            this.currentRow = row;
        },
        onTableDelete(ids, row) {
            this.$confirm(this.$t('pageKey41', '是否确定要删除!'), this.$t('pageKey42', '温馨提示'), {
                type: 'warning',
                confirmButtonText: this.$t('pageKey19', '确定'),
                cancelButtonText: this.$t('pageKey20', '取消'),
            }).then(() => {
                this.$api.Sp.ShopGoodsCat.delete({ ids }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    // this.onQuery();
                    const { treeTable } = this.$refs;
                    treeTable.removeNode(row);
                });
            });
        },
        onDelete() {
            const ids = this.currentTableSelect.reduce((prev, next) => {
                prev.push(next.id);
                return prev;
            }, []);
            this.onTableDelete(ids.join(','));
        },
        onAddDialogConfirm() {
            const { addForm } = this.$refs;
            const { formModel } = this.addDialog;
            addForm.validate().then(() => {
                this.$api.Sp.ShopGoodsCat.save({
                    ...formModel,
                }).then(json => {
                    const { msg, data } = json.data;
                    this.$message({
                        message: msg,
                        type: 'success',
                    });
                    const { treeTable } = this.$refs;
                    const { currentRow } = this;
                    if (!formModel.parentId && !formModel.id) {
                        // 新增根节点
                        treeTable.loadNode();
                    } else if (formModel.id) {
                        // 更新节点
                        Object.keys(currentRow).forEach(key => {
                            if (Utils.isDef(data[key])) currentRow[key] = data[key];
                        });
                        // 重新排序
                        currentRow._parent.children = currentRow._parent.children.sort((prev, next) => {
                            return prev.orderNum - next.orderNum;
                        });
                    } else if (formModel.parentId) {
                        // 新增子节点
                        treeTable.loadNode(currentRow);
                    }
                    this.addDialog.isVisible = false;
                });
            });
        },
        onAddDialogCancel() {
            const { addForm } = this.$refs;
            addForm.resetFields();
            this.addDialog.isVisible = false;
        },
        onIsAvailableInput(val, scope) {
            const { row } = scope;
            if (!row) return;
            this.$api.Sp.ShopGoodsCat.status({
                id: row.id,
                isAvailable: row.isAvailable,
            })
                .then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    const { treeTable } = this.$refs;
                    treeTable.refreshChildren(row);
                })
                .catch(() => {
                    this.$message({
                        message: this.$t('pageKey43', '操作失败请重试'),
                        type: 'error',
                    });
                    row.isAvailable = !row.isAvailable;
                });
        },
        /* -- 自定义校验 -- */
        validatorName(rule, value, callback) {
            this.$api.Sp.ShopGoodsCat.exists({
                pId: this.addDialog.formModel.parentId,
                id: this.addDialog.formModel.id,
                name: this.addDialog.formModel.name,
            }).then(res => {
                if (res.data.data) {
                    callback(rule.message);
                } else {
                    callback();
                }
            });
        },
        // 商品管理
        onGoodsForbidSell(row) {
            this.goodsForbidSellDialog.queryFormModel.shopGoodsCatId = row.id;
            this.$nextTick(() => {
                this.onGoodsForbidSellDialogQuery();
            });
            this.goodsForbidSellDialog.isVisible = true;
        },
        onGoodsForbidSellDialogQuery(pagination) {
            return this.$api.Gd.Goods.data({
                ...this.goodsForbidSellDialog.queryFormModel,
                ...pagination,
            }).then(json => {
                const res = json.data;
                this.goodsForbidSellDialog.tableData = res.data;
                this.goodsForbidSellDialog.pagination = res.pagination;
            });
        },
        onResetGoodsForbidSellDialog() {
            this.$refs.goodsForbidSellDialogQueryForm.resetFields();
        },
        onGoodsForbidSellDialogSelectionChange(val) {
            this.goodsForbidSellDialog.currentTableSelect = val;
        },
        onGoodsForbidSellCancel() {
            this.goodsForbidSellDialog.isVisible = false;
        },
        onSelectDialogSelectionChange(val) {
            this.selectDialog.currentTableSelect = val;
        },
        onAddSelect() {
            this.selectDialog.isVisible = true;
            this.$nextTick(() => {
                this.onSelectDialogQuery();
            });
        },
        onSelectDialogQuery(pagination) {
            return this.$api.Gd.Goods.data({
                ...this.selectDialog.queryFormModel,
                ...pagination,
                shopGoodsCatIdNE: this.goodsForbidSellDialog.queryFormModel.shopGoodsCatId,
            }).then(json => {
                const res = json.data;
                this.selectDialog.tableData = res.data;
                this.selectDialog.pagination = res.pagination;
            });
        },
        onResetSelectDialog() {
            this.$refs.selectDialogQueryForm.resetFields();
        },
        onSelectConfirm() {
            const ids = this.selectDialog.currentTableSelect.reduce((prev, next) => {
                prev.push(next.id);
                return prev;
            }, []);
            if (ids.length === 0) {
                this.$message({
                    message: this.$t('pageKey466', '请选择商品'),
                    type: 'error',
                });
                return;
            }
            this.$api.Gd.Goods.saveGoodsShopGoodsCat({
                goodsIds: ids.join(','),
                shopGoodsCatId: this.goodsForbidSellDialog.queryFormModel.shopGoodsCatId,
            }).then(json => {
                const res = json.data;
                this.$message({
                    message: res.msg,
                    type: 'success',
                });
                this.onGoodsForbidSellDialogQuery(this.goodsForbidSellDialog.pagination);
                this.selectDialog.isVisible = false;
            });
        },
        onSelectCancel() {
            this.selectDialog.isVisible = false;
        },
        onDeleteSelectBatch() {
            const ids = this.goodsForbidSellDialog.currentTableSelect.reduce((prev, next) => {
                prev.push(next.id);
                return prev;
            }, []);
            this.onDeleteSelect(ids.join(','));
        },
        onDeleteSelect(ids) {
            this.$confirm(this.$t('pageKey41', '是否确定要删除!'), this.$t('pageKey42', '温馨提示'), {
                type: 'warning',
                confirmButtonText: this.$t('pageKey19', '确定'),
                cancelButtonText: this.$t('pageKey20', '取消'),
            }).then(() => {
                this.$api.Gd.Goods.delGoodsShopGoodsCat({
                    goodsIds: ids,
                    shopGoodsCatId: this.goodsForbidSellDialog.queryFormModel.shopGoodsCatId,
                }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.onGoodsForbidSellDialogQuery(this.goodsForbidSellDialog.pagination);
                });
            });
        },
    },
};
</script>

<style lang="scss">
</style>
