<!-- Created by henian.xu on 2018/7/20. -->

<template>
    <page>
        <!-- 表单区 -->
        <template slot="secondHeader">
            <el-form
                ref="queryForm"
                class="second-header npa-b"
                size="small"
                :inline="true"
                :model="queryFormModel"
            >
                <el-form-item
                    prop="name"
                    :label="$t('pageKey447', '类目名称')"
                >
                    <el-input
                        v-model="queryFormModel.name"
                        :placeholder="$t('pageKey448', '请输入类目名称')"
                    />
                </el-form-item>
                <el-form-item
                    prop="isAvailable"
                    :label="$t('pageKey67', '状态')"
                >
                    <el-select
                        v-model="queryFormModel.isAvailable"
                        :placeholder="$t('pageKey101', '请选择')"
                    >
                        <el-option
                            :label="$t('pageKey6', '全部')"
                            value=""
                        />
                        <el-option
                            :label="$t('pageKey7', '启用')"
                            value="1"
                        />
                        <el-option
                            :label="$t('pageKey8', '禁用')"
                            value="0"
                        />
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button
                        type="primary"
                        @click="onQuery"
                    >
                        {{ $t('pageKey9', '查询') }}
                    </el-button>
                    <el-button
                        type="success"
                        @click="onReset"
                    >
                        {{ $t('pageKey10', '重置') }}
                    </el-button>
                </el-form-item>
            </el-form>
        </template>

        <!-- 内容 -->
        <div
            class="body"
            slot="body"
            v-loading="querying"
        >
            <!-- 操作 -->
            <div
                class="ma-b"
            >
                <el-button-group>
                    <el-button
                        type="success"
                        icon="el-icon-plus"
                        size="small"
                        @click="onAdd"
                    >
                        {{ $t('pageKey15', '新增') }}
                    </el-button>
                    <el-button
                        type="success"
                        size="small"
                        icon="el-icon-refresh-right"
                        @click="onSyncInventoryGoodsCat"
                        v-if="(userData.shop && userData.shop.isPushInventory) || (userData.platform && userData.platform.isPushInventory)"
                    >
                        {{ $t('pageKey449', '同步进销存商品分类') }}
                    </el-button>
                </el-button-group>
            </div>

            <!-- 表格 -->
            <tree-table
                :lazy-load="getListData"
                init-not-load
                :expand-props="{
                    prop:'name',
                    label: $t('pageKey447', '类目名称'),
                    minWidth:200,
                }"
                ref="treeTable"
                stripe
                border
                size="small"
                style="width: 100%"
                :empty-text="$t('pageKey977', '暂无数据')"
            >
                <el-table-column
                    prop="id"
                    :label="$t('pageKey447', '类目ID')"
                    min-width="100"
                />
                <el-table-column
                    :label="$t('pageKey12', '是否启用')"
                    width="90"
                >
                    <template slot-scope="scope">
                        <el-switch
                            v-model="scope.row.isAvailable"
                            @input="onIsAvailableInput($event,scope)"
                        />
                    </template>
                </el-table-column>
                <el-table-column
                    prop="orderNum"
                    :label="$t('pageKey11', '排序')"
                    min-width="80"
                />
                <el-table-column
                    fixed="right"
                    :label="$t('pageKey13', '操作')"
                    width="175"
                >
                    <template slot-scope="scope">
                        <el-button
                            type="text"
                            size="small"
                            @click="onTableAdd(scope.row)"
                        >
                            {{ $t('pageKey15', '新增') }}
                        </el-button>
                        <el-button
                            type="text"
                            size="small"
                            @click="onTableEdit(scope.row)"
                        >
                            {{ $t('pageKey16', '编辑') }}
                        </el-button>
                        <el-button
                            type="text"
                            size="small"
                            @click="onTableDelete(scope.row.id,scope.row)"
                        >
                            {{ $t('pageKey17', '删除') }}
                        </el-button>
                        <el-button
                            type="text"
                            size="small"
                            @click="onGoodsCatUser(scope.row)"
                        >
                            {{ $t('pageKey451', '管理员') }}
                        </el-button>
                    </template>
                </el-table-column>
            </tree-table>
        </div>

        <!-- 弹框 -->
        <!-- 变量新增/编辑 -->
        <el-dialog
            :title="addDialog.formModel.id ? $t('pageKey16', '编辑') : $t('pageKey15', '新增')"
            center
            width="1200px"
            :visible.sync="addDialog.isVisible"
            @closed="onAddDialogCancel"
        >
            <el-form
                ref="addForm"
                size="small"
                :model="addDialog.formModel"
                :rules="addDialog.formRules"
            >
                <el-form-item
                    v-if="addDialog.formModel.parentId"
                    prop="parentName"
                    :label="$t('pageKey452', '上级分类')"
                    label-width="11em"
                >
                    <el-input
                        v-model="addDialog.formModel.parentName"
                        disabled
                    />
                </el-form-item>
                <el-form-item
                    prop="name"
                    :label="$t('pageKey447', '类目名称')"
                    label-width="11em"
                >
                    <el-input
                        v-model="addDialog.formModel.name"
                    />
                </el-form-item>
                <el-form-item
                    prop="shareIncomeRate"
                    :label="$t('pageKey316', '分销分享赚比例(%)')"
                    label-width="11em"
                    v-if="userData.shop && (1 & userData.shop.distributionIncomeMode) !== 0"
                >
                    <el-input-number
                        controls-position="right"
                        :min="0"
                        :precision="2"
                        v-model.number="addDialog.formModel.shareIncomeRate"
                    />
                </el-form-item>
                <el-form-item
                    prop="topIncomeRate"
                    :label="$t('pageKey317', '分销顶级提成比例(%)')"
                    label-width="11em"
                    v-if="userData.shop && (2 & userData.shop.distributionIncomeMode) !== 0"
                >
                    <el-input-number
                        controls-position="right"
                        :min="0"
                        :precision="2"
                        v-model.number="addDialog.formModel.topIncomeRate"
                    />
                </el-form-item>
                <el-form-item
                    prop="levelIncomeRate"
                    :label="$t('pageKey318', '分销层级提成比例')"
                    label-width="11em"
                    v-if="userData.shop && ((4 & userData.shop.distributionIncomeMode) !== 0 || (8 & userData.shop.distributionIncomeMode) !== 0)"
                >
                    <el-table
                        stripe
                        border
                        size="small"
                        :data="addDialog.levelIncomeRateList"
                        style="width: 100%"
                    >
                        <el-table-column
                            prop="name"
                            :label="$t('pageKey319', '分销层级')"
                            width="120"
                        />
                        <el-table-column
                            prop="levelIncomeRate"
                            :label="$t('pageKey320', '默认比例(%)')"
                            width="150"
                        >
                            <template slot-scope="scope">
                                <el-input-number
                                    size="mini"
                                    controls-position="right"
                                    :min="0"
                                    :precision="2"
                                    v-model.number="scope.row.levelIncomeRate"
                                />
                            </template>
                        </el-table-column>
                        <el-table-column
                            v-for="(item,index) in distributorLevelList"
                            :key="index"
                            :label="item.levelName +  $t('pageKey321', '提成比例(%)')"
                            width="150"
                        >
                            <template slot-scope="scope">
                                <el-input-number
                                    size="mini"
                                    controls-position="right"
                                    :precision="2"
                                    v-model.number="scope.row.distributorLevelRateList[index].levelIncomeRate"
                                />
                            </template>
                        </el-table-column>
                    </el-table>
                </el-form-item>
                <el-form-item
                    prop="liveShareIncomeRate"
                    :label="$t('pageKey322', '直播分享赚比例(%)')"
                    label-width="11em"
                    v-if="userData.shop && (16 & userData.shop.distributionIncomeMode) !== 0"
                >
                    <el-input-number
                        controls-position="right"
                        :min="0"
                        :precision="2"
                        v-model.number="addDialog.formModel.liveShareIncomeRate"
                    />
                </el-form-item>
                <el-form-item
                    prop="anchorIncomeRate"
                    :label="$t('pageKey323', '主播提成比例(%)')"
                    label-width="11em"
                    v-if="userData.shop && (32 & userData.shop.distributionIncomeMode) !== 0"
                >
                    <el-input-number
                        controls-position="right"
                        :min="0"
                        :precision="2"
                        v-model.number="addDialog.formModel.anchorIncomeRate"
                    />
                </el-form-item>
                <!--                <el-form-item
                    label="会员等级限购"
                    label-width="11em"
                    v-if="userData.appId !== 1"
                >
                    <el-checkbox-group v-model="buyerLevelLimit">
                        <el-checkbox
                            v-for="item in buyerLevelList"
                            :key="item.id"
                            :label="item.id"
                        >
                            {{ item.levelName }}
                        </el-checkbox>
                    </el-checkbox-group>
                </el-form-item>
                <el-form-item
                    label="分销等级限购"
                    label-width="11em"
                    v-if="userData.appId !== 1"
                >
                    <el-checkbox-group v-model="distributorLevelLimit">
                        <el-checkbox
                            v-for="item in distributorLevelList"
                            :key="item.id"
                            :label="item.id"
                        >
                            {{ item.levelName }}
                        </el-checkbox>
                    </el-checkbox-group>
                </el-form-item>-->
                <el-form-item
                    prop="gstRate"
                    :label="$t('pageKey282', 'GST比例(%)')"
                    label-width="11em"
                    v-if="userData.shop && userData.shop.goodsSendModes.split(',').includes('2')"
                >
                    <el-input-number
                        controls-position="right"
                        :min="0"
                        :precision="2"
                        v-model.number="addDialog.formModel.gstRate"
                    />
                </el-form-item>
                <!--                <el-form-item
                            label="会员级别价格比例"
                            label-width="11em"
                            v-if="userData.appId !== 1"
                        >
                            <el-table
                                stripe
                                border
                                size="small"
                                :data="addDialog.buyerLevelPriceRateList"
                                style="width: 100%"
                            >
                                <el-table-column
                                    prop="levelName"
                                    label="会员等级"
                                    width="120"
                                />
                                <el-table-column
                                    prop="priceRate"
                                    label="价格比例(%)"
                                    min-width="220"
                                >
                                    <template slot-scope="scope">
                                        <el-input-number
                                            controls-position="right"
                                            :min="0.01"
                                            :precision="2"
                                            v-model.number="scope.row.priceRate"
                                        />
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-form-item>-->
                <el-form-item
                    prop="orderNum"
                    :label="$t('pageKey11', '排序')"
                    label-width="11em"
                >
                    <el-input-number
                        controls-position="right"
                        :min="1"
                        v-model.number="addDialog.formModel.orderNum"
                    />
                </el-form-item>
            </el-form>
            <div
                slot="footer"
                class="dialog-footer"
            >
                <el-button
                    type="primary"
                    size="small"
                    @click="onAddDialogConfirm"
                >
                    {{ $t('pageKey19', '确 定') }}
                </el-button>
                <el-button
                    size="small"
                    @click="onAddDialogCancel"
                >
                    {{ $t('pageKey20', '取 消') }}
                </el-button>
            </div>
        </el-dialog>

        <el-dialog
            :title="$t('pageKey451', '管理员')"
            center
            width="1200px"
            :visible.sync="goodsCatUserDialog.isVisible"
            @closed="onGoodsCatUserCancel"
        >
            <!-- 表单区 -->
            <el-form
                ref="goodsCatUserDialogQueryForm"
                class="second-header npa-b"
                size="small"
                :inline="true"
                :model="goodsCatUserDialog.queryFormModel"
            >
                <el-form-item
                    prop="userName"
                    :label="$t('pageKey63', '用户名')"
                >
                    <el-input
                        v-model="goodsCatUserDialog.queryFormModel.userName"
                        :placeholder="$t('pageKey64', '请输入用户名')"
                    />
                </el-form-item>
                <el-form-item
                    prop="fullName"
                    :label="$t('pageKey65', '姓名')"
                >
                    <el-input
                        v-model="goodsCatUserDialog.queryFormModel.fullName"
                        :placeholder="$t('pageKey66', '请输入姓名')"
                    />
                </el-form-item>
                <el-form-item>
                    <el-button
                        type="primary"
                        @click="onGoodsCatUserDialogQuery()"
                    >
                        {{ $t('pageKey9', '查询') }}
                    </el-button>
                    <el-button
                        type="success"
                        @click="onResetGoodsCatUserDialog"
                    >
                        {{ $t('pageKey10', '重置') }}
                    </el-button>
                </el-form-item>
            </el-form>

            <!-- 内容 -->
            <div class="body">
                <!-- 操作 -->
                <div
                    class="ma-b"
                >
                    <el-button-group>
                        <el-button
                            type="success"
                            icon="el-icon-plus"
                            size="small"
                            @click="onAddSelect"
                        >
                            {{ $t('pageKey453', '选择管理员') }}
                        </el-button>
                        <el-button
                            type="danger"
                            icon="el-icon-delete"
                            size="small"
                            @click="onDeleteGoodsCatUser"
                            :disabled="!goodsCatUserDialog.currentTableSelect.length"
                        >
                            {{ $t('pageKey17', '删除') }}
                        </el-button>
                    </el-button-group>
                </div>

                <!-- 表格 -->
                <el-table
                    stripe
                    border
                    size="small"
                    :data="goodsCatUserDialog.tableData"
                    style="width: 100%"
                    height="400"
                    row-key="id"
                    ref="goodsCatUserDialogTable"
                    @selection-change="onGoodsCatUserDialogSelectionChange"
                >
                    <el-table-column
                        type="selection"
                        width="36"
                    />
                    <el-table-column
                        prop="userName"
                        :label="$t('pageKey63', '用户名')"
                        min-width="100"
                    />
                    <el-table-column
                        prop="fullName"
                        :label="$t('pageKey65', '姓名')"
                        min-width="100"
                    />
                    <el-table-column
                        prop="orgName"
                        :label="$t('pageKey69', '部门')"
                        min-width="100"
                    />
                    <el-table-column
                        prop="userStatusName"
                        :label="$t('pageKey68', '用户状态')"
                        min-width="100"
                    />
                    <el-table-column
                        fixed="right"
                        :label="$t('pageKey13', '操作')"
                        width="90"
                    >
                        <template slot-scope="scope">
                            <el-button
                                type="text"
                                size="small"
                                @click="onTableDeleteGoodsCatUser(scope.row.id)"
                            >
                                {{ $t('pageKey17', '删除') }}
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>

                <!-- 分页 -->
                <div class="ma-t ta-r">
                    <pagination
                        v-model="goodsCatUserDialog.pagination"
                        @input="onGoodsCatUserDialogQuery"
                    />
                </div>
            </div>
            <div
                slot="footer"
                class="dialog-footer"
            >
                <el-button
                    size="small"
                    @click="onGoodsCatUserCancel"
                >
                    {{ $t('pageKey126', '返 回') }}
                </el-button>
            </div>
        </el-dialog>

        <el-dialog
            :title="$t('pageKey454', '新增管理员')"
            center
            width="1200px"
            :visible.sync="selectDialog.isVisible"
            @closed="onSelectCancel"
        >
            <!-- 表单区 -->
            <el-form
                ref="selectDialogQueryForm"
                class="second-header npa-b"
                size="small"
                :inline="true"
                :model="selectDialog.queryFormModel"
            >
                <el-form-item
                    prop="userName"
                    :label="$t('pageKey63', '用户名')"
                >
                    <el-input
                        v-model="selectDialog.queryFormModel.userName"
                        :placeholder="$t('pageKey64', '请输入用户名')"
                    />
                </el-form-item>
                <el-form-item
                    prop="fullName"
                    :label="$t('pageKey65', '姓名')"
                >
                    <el-input
                        v-model="selectDialog.queryFormModel.fullName"
                        :placeholder="$t('pageKey66', '请输入姓名')"
                    />
                </el-form-item>
                <el-form-item>
                    <el-button
                        type="primary"
                        @click="onSelectDialogQuery()"
                    >
                        {{ $t('pageKey9', '查询') }}
                    </el-button>
                    <el-button
                        type="success"
                        @click="onResetSelectDialog"
                    >
                        {{ $t('pageKey10', '重置') }}
                    </el-button>
                </el-form-item>
            </el-form>

            <!-- 内容 -->
            <div class="body">
                <!-- 表格 -->
                <el-table
                    stripe
                    border
                    size="small"
                    :data="selectDialog.tableData"
                    style="width: 100%"
                    height="400"
                    row-key="id"
                    ref="selectDialogTable"
                    @selection-change="onSelectDialogSelectionChange"
                >
                    <el-table-column
                        type="selection"
                        :reserve-selection="true"
                        width="36"
                    />
                    <el-table-column
                        prop="userName"
                        :label="$t('pageKey63', '用户名')"
                        min-width="100"
                    />
                    <el-table-column
                        prop="fullName"
                        :label="$t('pageKey65', '姓名')"
                        min-width="100"
                    />
                    <el-table-column
                        prop="orgName"
                        :label="$t('pageKey69', '部门')"
                        min-width="100"
                    />
                    <el-table-column
                        prop="userStatusName"
                        :label="$t('pageKey68', '用户状态')"
                        min-width="100"
                    />
                </el-table>

                <!-- 分页 -->
                <div class="ma-t ta-r">
                    <pagination
                        v-model="selectDialog.pagination"
                        @input="onSelectDialogQuery"
                    />
                </div>
            </div>
            <div
                slot="footer"
                class="dialog-footer"
            >
                <el-button
                    type="primary"
                    size="small"
                    @click="onSelectConfirm()"
                >
                    {{ $t('pageKey19', '确 定') }}
                </el-button>
                <el-button
                    size="small"
                    @click="onSelectCancel"
                >
                    {{ $t('pageKey20', '取 消') }}
                </el-button>
            </div>
        </el-dialog>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';
import { queryFormTree } from 'vmf/src/mixins';
import { Utils } from 'vmf';

export default {
    name: 'GoodsCat',
    mixins: [pagesMixin, queryFormTree],
    data() {
        return {
            queryFormModel: {
                name: '', // 类目名称
                isAvailable: '', // 状态
            },
            // 新增/编辑
            addDialog: {
                // 对话框显示
                isVisible: false,
                // 表单数据
                formModel: {
                    id: '',
                    parentId: '',
                    parentName: '',
                    name: '',
                    shareIncomeRate: undefined,
                    levelIncomeRate: '',
                    topIncomeRate: undefined,
                    orderNum: 1,
                    isAvailable: true,
                    gstRate: undefined,
                    liveShareIncomeRate: undefined,
                    anchorIncomeRate: undefined,
                },
                // 表单校验规则
                formRules: {
                    name: [
                        {
                            required: true,
                            message: this.$t('pageKey448', '请输入类目名称'),
                            trigger: 'blur',
                        },
                        {
                            validator: this.validatorName,
                            message: this.$t('pageKey455', '类目名称不能重复'),
                            trigger: 'blur',
                        },
                    ],
                },
                levelIncomeRateList: [],
                buyerLevelPriceRateList: [],
            },
            // 表格数据
            // tableData: [],
            // 表格当前行
            currentRow: {},
            // 表格当前选中行
            currentTableSelect: [],
            buyerLevelLimit: [],
            distributorLevelLimit: [],
            buyerLevelList: [],
            distributorLevelList: [],
            goodsCatUserDialog: {
                // 对话框显示
                isVisible: false,
                // 查询数据
                queryFormModel: {
                    userName: '', // 用户名
                    fullName: '', // 姓名
                    goodsCatId: '',
                },
                // 表单数据
                tableData: [],
                // 分页
                pagination: {},
                // 当前选择行
                currentTableSelect: [],
            },
            selectDialog: {
                // 对话框显示
                isVisible: false,
                // 查询数据
                queryFormModel: {
                    userName: '', // 用户名
                    fullName: '', // 姓名
                },
                // 表单数据
                tableData: [],
                // 分页
                pagination: {},
                // 当前选择行
                currentTableSelect: [],
            },
        };
    },
    methods: {
        /* -- 方法 -- */
        // 请求列表数据
        getListData({ row = {} } = {}) {
            this.querying = true;
            return this.$api.Gd.GoodsCat.data({
                pId: row ? row.id : null,
                ...this.queryFormModel,
            }).then(json => {
                this.querying = false;
                const res = json.data.data.sort((prev, next) => {
                    return prev.orderNum - next.orderNum;
                });
                return Promise.resolve(res);
            });
        },
        /* -- 事件 -- */
        onAdd() {
            this.addDialog.isVisible = true;
            this.addDialog.levelIncomeRateList = [];
            this.$nextTick(() => {
                const { formModel } = this.addDialog;
                const { addForm } = this.$refs;
                addForm.resetFields();
                // id/parentId 不在表单之内所以要手机重置
                formModel.id = '';
                formModel.parentId = '';
            });
            if (this.userData.appId !== 1) {
                this.initLevelIncomeRateList();
                this.addDialog.buyerLevelPriceRateList = JSON.parse(JSON.stringify(this.buyerLevelList));
            }
        },
        onTableSelectionChange(val) {
            this.currentTableSelect = val;
        },
        onTableAdd(row) {
            this.addDialog.isVisible = true;
            this.$nextTick(() => {
                const { formModel } = this.addDialog;
                const { addForm } = this.$refs;
                addForm.resetFields();
                formModel.id = '';
                formModel.parentId = row.id;
                formModel.parentName = row.name;
            });
            this.currentRow = row;
            if (this.userData.appId !== 1) {
                this.initLevelIncomeRateList();
                this.addDialog.buyerLevelPriceRateList = JSON.parse(JSON.stringify(this.buyerLevelList));
            }
        },
        onTableEdit(row) {
            const { formModel } = this.addDialog;
            this.buyerLevelLimit = [];
            this.distributorLevelLimit = [];
            this.$api.Gd.GoodsCat.getDetail({ id: row.id }).then(json => {
                const res = json.data.data;
                this.$utils.formModelMerge(formModel, res);
                if (this.userData.appId !== 1) {
                    this.addDialog.levelIncomeRateList = JSON.parse(res.levelIncomeRate) || [];
                    this.initLevelIncomeRateList();
                    if (res.goodsCatPermission) {
                        res.goodsCatPermission.split(',').forEach(item => {
                            const limitItem = item.split('-');
                            if (limitItem[0] === 'buyerLevel') {
                                this.buyerLevelLimit.push(+limitItem[1]);
                            } else if (limitItem[0] === 'distributorLevel') {
                                this.distributorLevelLimit.push(+limitItem[1]);
                            }
                        });
                    }
                    const buyerLevelPriceRate = JSON.parse(res.buyerLevelPriceRate) || {};
                    const buyerLevelPriceRateList = JSON.parse(JSON.stringify(this.buyerLevelList));
                    buyerLevelPriceRateList.forEach(item => {
                        item.priceRate = buyerLevelPriceRate[item.id];
                    });
                    this.addDialog.buyerLevelPriceRateList = buyerLevelPriceRateList;
                }
            });
            this.addDialog.isVisible = true;
            this.currentRow = row;
        },
        onTableDelete(ids, row) {
            this.$confirm(this.$t('pageKey41', '是否确定要删除!'), this.$t('pageKey42', '温馨提示'), {
                type: 'warning',
                confirmButtonText: this.$t('pageKey19', '确定'),
                cancelButtonText: this.$t('pageKey20', '取消'),
            }).then(() => {
                this.$api.Gd.GoodsCat.delete({ ids }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    // this.onQuery();
                    const { treeTable } = this.$refs;
                    treeTable.removeNode(row);
                });
            });
        },
        onDelete() {
            const ids = this.currentTableSelect.reduce((prev, next) => {
                prev.push(next.id);
                return prev;
            }, []);
            this.onTableDelete(ids.join(','));
        },
        onAddDialogConfirm() {
            const { addForm } = this.$refs;
            const { formModel } = this.addDialog;
            const goodsPermissionList = [];
            this.buyerLevelLimit.forEach(item => {
                goodsPermissionList.push(`buyerLevel-${item}`);
            });
            this.distributorLevelLimit.forEach(item => {
                goodsPermissionList.push(`distributorLevel-${item}`);
            });
            const buyerLevelPriceRate = {};
            this.addDialog.buyerLevelPriceRateList.forEach(item => {
                buyerLevelPriceRate[item.id] = item.priceRate;
            });
            this.addDialog.levelIncomeRateList.forEach(item => {
                const distributorLevelRateList = [];
                item.distributorLevelRateList.forEach(item1 => {
                    distributorLevelRateList.push({ id: item1.id, levelIncomeRate: item1.levelIncomeRate });
                });
                item.distributorLevelRateList = distributorLevelRateList;
            });
            addForm.validate().then(() => {
                this.$api.Gd.GoodsCat.save({
                    ...formModel,
                    levelIncomeRate: JSON.stringify(this.addDialog.levelIncomeRateList),
                    goodsCatPermission: goodsPermissionList.join(','),
                    buyerLevelPriceRate: JSON.stringify(buyerLevelPriceRate),
                }).then(json => {
                    const { msg, data } = json.data;
                    this.$message({
                        message: msg,
                        type: 'success',
                    });
                    const { treeTable } = this.$refs;
                    const { currentRow } = this;
                    if (!formModel.parentId && !formModel.id) {
                        // 新增根节点
                        treeTable.loadNode();
                    } else if (formModel.id) {
                        // 更新节点
                        Object.keys(currentRow).forEach(key => {
                            if (Utils.isDef(data[key])) currentRow[key] = data[key];
                        });
                        // 重新排序
                        currentRow._parent.children = currentRow._parent.children.sort((prev, next) => {
                            return prev.orderNum - next.orderNum;
                        });
                    } else if (formModel.parentId) {
                        // 新增子节点
                        treeTable.loadNode(currentRow);
                    }
                    this.addDialog.isVisible = false;
                });
            });
        },
        onAddDialogCancel() {
            const { addForm } = this.$refs;
            addForm.resetFields();
            this.addDialog.isVisible = false;
        },
        onIsAvailableInput(val, scope) {
            const { row } = scope;
            if (!row) return;
            this.$api.Gd.GoodsCat.status({
                id: row.id,
                isAvailable: row.isAvailable,
            })
                .then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    const { treeTable } = this.$refs;
                    treeTable.refreshChildren(row);
                })
                .catch(() => {
                    this.$message({
                        message: this.$t('pageKey43', '操作失败请重试'),
                        type: 'error',
                    });
                    row.isAvailable = !row.isAvailable;
                });
        },
        initLevelIncomeRateList() {
            const newlevelIncomeRateList = [];
            for (let num = 1; num <= this.userData.shop.distributionLevel; num += 1) {
                let isExit = false;
                this.addDialog.levelIncomeRateList.forEach(item => {
                    if (item.id === num) {
                        isExit = true;
                        newlevelIncomeRateList.push(item);
                    }
                });
                if (!isExit) {
                    const levelIncomeRate = {};
                    levelIncomeRate.id = num;
                    levelIncomeRate.name = `${num} ${this.$t('pageKey456', '级分销商')}`;
                    newlevelIncomeRateList.push(levelIncomeRate);
                }
            }
            newlevelIncomeRateList.sort((a, b) => a.id - b.id);
            newlevelIncomeRateList.forEach(item => {
                if (!item.distributorLevelRateList) item.distributorLevelRateList = [];
                const distributorLevelRateList = [];
                this.distributorLevelList.forEach(item1 => {
                    let isExit = false;
                    item.distributorLevelRateList.forEach(item2 => {
                        if (item1.id === item2.id) {
                            isExit = true;
                            distributorLevelRateList.push(item2);
                        }
                    });
                    if (!isExit) {
                        distributorLevelRateList.push({ id: item1.id, levelIncomeRate: undefined });
                    }
                });
                item.distributorLevelRateList = distributorLevelRateList;
            });
            this.addDialog.levelIncomeRateList = [...newlevelIncomeRateList];
        },
        /* -- 自定义校验 -- */
        validatorName(rule, value, callback) {
            this.$api.Gd.GoodsCat.exists({
                pId: this.addDialog.formModel.parentId,
                id: this.addDialog.formModel.id,
                name: this.addDialog.formModel.name,
            }).then(res => {
                if (res.data.data) {
                    callback(rule.message);
                } else {
                    callback();
                }
            });
        },
        initBuyerLevelList() {
            this.$api.Mb.BuyerLevel.select().then(json => {
                const res = json.data.data;
                this.buyerLevelList = res;
            });
        },
        initDistributorLevelList() {
            this.$api.Mb.DistributorLevel.select().then(json => {
                const res = json.data.data;
                this.distributorLevelList = res;
            });
        },
        onGoodsCatUser(row) {
            this.goodsCatUserDialog.isVisible = true;
            this.goodsCatUserDialog.queryFormModel.goodsCatId = row.id;
            this.$nextTick(() => {
                this.onGoodsCatUserDialogQuery();
                // 初始化已选择数据
                if (this.$refs.goodsCatUserDialogTable) {
                    this.$refs.goodsCatUserDialogTable.clearSelection();
                }
            });
        },
        onGoodsCatUserDialogQuery(pagination) {
            return this.$api.Rs.User.data({
                ...this.goodsCatUserDialog.queryFormModel,
                ...pagination,
            }).then(json => {
                const res = json.data;
                this.goodsCatUserDialog.tableData = res.data;
                this.goodsCatUserDialog.pagination = res.pagination;
            });
        },
        onGoodsCatUserDialogSelectionChange(val) {
            this.goodsCatUserDialog.currentTableSelect = val;
        },
        onResetGoodsCatUserDialog() {
            this.$refs.goodsCatUserDialogQueryForm.resetFields();
        },
        onGoodsCatUserCancel() {
            this.goodsCatUserDialog.isVisible = false;
        },
        onAddSelect() {
            this.selectDialog.isVisible = true;
            this.$nextTick(() => {
                this.onSelectDialogQuery();
                // 初始化已选择数据
                if (this.$refs.selectDialogTable) {
                    this.$refs.selectDialogTable.clearSelection();
                }
            });
        },
        onSelectDialogQuery(pagination) {
            return this.$api.Rs.User.data({
                ...this.selectDialog.queryFormModel,
                ...pagination,
                goodsCatIdNE: this.goodsCatUserDialog.queryFormModel.goodsCatId,
            }).then(json => {
                const res = json.data;
                this.selectDialog.tableData = res.data;
                this.selectDialog.pagination = res.pagination;
            });
        },
        onSelectDialogSelectionChange(val) {
            this.selectDialog.currentTableSelect = val;
        },
        onResetSelectDialog() {
            this.$refs.selectDialogQueryForm.resetFields();
        },
        onSelectConfirm() {
            const ids = this.selectDialog.currentTableSelect.reduce((prev, next) => {
                prev.push(next.id);
                return prev;
            }, []);
            if (ids.length === 0) {
                this.$message({
                    message: this.$t('pageKey457', '请选择用户'),
                    type: 'error',
                });
                return;
            }
            this.$api.Gd.GoodsCatUser.save({
                goodsCatId: this.goodsCatUserDialog.queryFormModel.goodsCatId,
                userIds: ids.join(','),
            }).then(json => {
                const res = json.data;
                this.$message({
                    message: res.msg,
                    type: 'success',
                });
                this.onGoodsCatUserDialogQuery(this.goodsCatUserDialog.pagination);
                this.selectDialog.isVisible = false;
            });
        },
        onSelectCancel() {
            this.selectDialog.isVisible = false;
        },
        onTableDeleteGoodsCatUser(ids) {
            this.$confirm(this.$t('pageKey41', '是否确定要删除!'), this.$t('pageKey42', '温馨提示'), {
                type: 'warning',
                confirmButtonText: this.$t('pageKey19', '确定'),
                cancelButtonText: this.$t('pageKey20', '取消'),
            }).then(() => {
                this.$api.Gd.GoodsCatUser.delete({
                    userIds: ids,
                    goodsCatId: this.goodsCatUserDialog.queryFormModel.goodsCatId,
                }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.onGoodsCatUserDialogQuery(this.goodsCatUserDialog.pagination);
                });
            });
        },
        onDeleteGoodsCatUser() {
            const ids = this.goodsCatUserDialog.currentTableSelect.reduce((prev, next) => {
                prev.push(next.id);
                return prev;
            }, []);
            this.onTableDeleteGoodsCatUser(ids.join(','));
        },
        onSyncInventoryGoodsCat() {
            this.$confirm(this.$t('pageKey458', '是否确定要同步进销存商品分类!'), this.$t('pageKey42', '温馨提示'), {
                type: 'warning',
                confirmButtonText: this.$t('pageKey19', '确定'),
                cancelButtonText: this.$t('pageKey20', '取消'),
            }).then(() => {
                this.$api.Rs.Inventory.syncInventoryGoodsCat().then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.onQuery(this.pagination);
                });
            });
        },
    },
    created() {
        this.initBuyerLevelList();
        this.initDistributorLevelList();
    },
};
</script>

<style lang="scss">
</style>
